import $ from "jquery";

export const adjustSpanningGallleryProjecten = (query) => {
  const init = () => {
    try {
      exec();
    } catch (err) {
      console.error(err);
    }
  };
  const exec = () => {
    let parent = query.closest(".elementor-widget-container");
    let target = parent.find(".mi-projecten-query__gallery ul li");
    let countGallery = target.length;

    console.log(parent.find(".mi-projecten-query__container").height());

    if (countGallery)
      switch (countGallery) {
        case 4:
          target.eq(3).addClass("no-overlay");
          break;
        case 3:
          target.eq(2).css({ gridColumn: "1/3" });
          break;
        case 2:
          target.css({
            gridColumn: "1/3",
            width: window.innerWidth < 1024 ? "calc(100vw - 32px)" : "580px",
          });
          break;
        case 1:
          target.css({
            width: window.innerWidth < 1024 ? "calc(100vw - 32px)" : "580px",
            height:
              window.innerWidth === 1024
                ? "unset"
                : "min(calc(100vw - 48px), " +
                  parent.find(".mi-projecten-query__container").height() +
                  "px)!important",
            gridColumn: "1/3",
            gridRow: "1/3",
          });

          target
            .find("img")
            .attr(
              "style",
              `width:${
                window.innerWidth < 1024 ? "calc(100vw - 32px)" : "580px"
              };height:min(calc(100vw - 48px), ${parent
                .find(".mi-projecten-query__container")
                .height()}px)!important;`
            );
          break;
        default:
          window.innerWidth >= 1024
            ? target.find("img").css({
                width: "280px",
              })
            : null;
      }
  };
  init();
};
