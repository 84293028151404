import $ from "jquery";
import { miHeightProjectenQuery } from "../custom/miHeightProjectenQuery";
import { adjustSpanningGallleryProjecten } from "../custom/adjustSpanningGallleryProjecten";

const ajaxMiClickItemProjecten = () => {
  let query_mechanisch = "a.mi-projecten-mechanisch__chipbtn";
  let query_reguliere = "a.mi-projecten-reguliere__chipbtn";
  const init = () => {
    $(document).ready(function () {
      if ($(query_mechanisch).length || $(query_reguliere).length) {
        exec();
        $(query_mechanisch + ":first").trigger("click");
        $(query_reguliere + ":first").trigger("click");
      }
    });
  };
  const exec = () => {
    $(query_mechanisch + ", " + query_reguliere).on("click", function (e) {
      e.preventDefault();
      let el = $(this);
      let type = $(this).attr("data-type");
      if (type === "mechanisch-borstelen")
        $(query_mechanisch).removeClass("active");
      if (type === "reguliere-gevelreiniging")
        $(query_reguliere).removeClass("active");
      $(this).addClass("active");
      var data = {
        action: "projecten_show_item",
        ID: $(this).attr("data-id"),
        type: type,
      };

      $.post(parameters.url_admin_ajax, data, function (response) {
        // Replace content with response post
        if (type === "mechanisch-borstelen")
          $(".mi-projecten-mechanisch__container").html(response.data);
        if (type === "reguliere-gevelreiniging")
          $(".mi-projecten-reguliere__container").html(response.data);
        
        adjustSpanningGallleryProjecten(el);
        miHeightProjectenQuery();
      });
    });
  };
  init();
};

try {
  ajaxMiClickItemProjecten();
} catch (err) {
  console.error(err);
}
