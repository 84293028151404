import $ from "jquery";
import "./ajax/miClickItemProjecten";
import "./custom/miWidthProjectenQuery";
import { miHeightProjectenQuery } from "./custom/miHeightProjectenQuery";
import { miNavbarMain } from "./custom/miNavbarMain";

miNavbarMain();

$(window).on("resize", function () {
  miHeightProjectenQuery();
});
