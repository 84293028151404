import $ from "jquery";

export const miNavbarMain = () => {
  const targetJquery = $(`header > div`);
  const init = () => {
    $(document).ready(function () {
      if (!$("body.elementor-page-17").length) {
        targetJquery.css({
          background: "rgba(255, 255, 255, 0.75)",
          backdropFilter: "blur(10px)",
        });
      } else if ($(document).scrollTop() >= $(".hero-banner").innerHeight()) {
        targetJquery.css({
          background: "rgba(255, 255, 255, 0.75)",
          backdropFilter: "blur(10px)",
        });
      }
    });

    try {
      if ($("body.elementor-page-17").length) {
        exec();
      }
    } catch (e) {
      console.error(e);
    }
  };
  const exec = () => {
    $(document).on("scroll", function () {
      if ($(document).scrollTop() >= $(".hero-banner").innerHeight()) {
        targetJquery.css({
          background: "rgba(255, 255, 255, 0.75)",
          backdropFilter: "blur(10px)",
        });
      } else {
        targetJquery.css({
          backgroundColor: "transparent",
          backgroundImage:
            "linear-gradient(180deg, #FFFFFF80 0%, #FFFFFF00 100%)",
          backdropFilter: "unset",
        });
      }
    });
  };
  init();
};
