import $ from "jquery";

export const miHeightProjectenQuery = () => {
  const parentQuery = {
    reguliere: ".mi-projecten-reguliere__container",
    mechanisch: ".mi-projecten-mechanisch__container",
  };
  const init = () => {
    try {
      if (window.innerWidth >= 1024) {
        exec();
      }
    } catch (err) {
      console.error(err);
    }
  };
  const exec = () => {
    let queryContainerHeightMech = ($(
      parentQuery.mechanisch + " .mi-projecten-query__main"
    ).outerHeight() + $(
      parentQuery.mechanisch + " .mi-projecten-query__thumbnail"
    ).outerHeight()) / 2;
    let queryContainerHeightReg = ($(
      parentQuery.reguliere + " .mi-projecten-query__main"
    ).outerHeight() + $(
      parentQuery.reguliere + " .mi-projecten-query__thumbnail"
    ).outerHeight()) / 2;

    let countGalleryMech = $(parentQuery.mechanisch).find(".mi-projecten-query__gallery ul li").length;
    let countGalleryReg = $(parentQuery.reguliere).find(".mi-projecten-query__gallery ul li").length;

    // Change thumbnail image height
    $(parentQuery.mechanisch + " .mi-projecten-query__thumbnail img").height(
      queryContainerHeightMech
    );

    // change image thumbnail height
    if ($(parentQuery.mechanisch + " .mi-projecten-query__gallery img").length && countGalleryMech > 1)
      setTimeout(() => {
        $(parentQuery.mechanisch + " .mi-projecten-query__gallery img").css({
          height: queryContainerHeightMech - 10,
        });
      }, 10);

    // Change thumbnail image height
    $(parentQuery.reguliere + " .mi-projecten-query__thumbnail img").height(
      queryContainerHeightReg
    );

    // change image thumbnail height
    if ($(parentQuery.reguliere + " .mi-projecten-query__gallery img").length && countGalleryReg > 1)
      setTimeout(() => {
        $(parentQuery.reguliere + " .mi-projecten-query__gallery img").css({
          height: queryContainerHeightReg - 10,
        });
      }, 10);
  };
  init();
};
