import $ from "jquery";

const miWidthProjectenQuery = () => {
  let query = ".mi-projecten-query__gallery img";
  const init = () => {
    $(document).on("orientationchange", function () {
      exec();
    });
  };
  const exec = () => {
    $(query).css({ width: "100%" });
  };
  init();
};

try {
  miWidthProjectenQuery();
} catch (err) {
  console.error(err);
}
